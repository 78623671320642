import { useEffect, useState } from 'react';
import '../App.css';
import { useNavigate, useLocation } from 'react-router-dom';
import { TailSpin } from 'react-loader-spinner';
import ApiMethods from '../ApiHelper/ApiMethods';

function Contacted() {
    const location = useLocation();
    const { appsGuid, requestId, logo, agency, firstName, lastName, profilePhoto } = location.state || {};
    const navigate = useNavigate();

    const [loading, setLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState(null);

    useEffect(() => {
        if (logo && agency && firstName && lastName && profilePhoto) {
            setLoading(false);
        }
    }, [logo, agency, firstName, lastName, profilePhoto]);

    const getLinkText = (agency) => {
        if (agency?.includes('travelleaders')) {
            return 'Travelleaders';
        } else if (agency?.includes('vacation')) {
            return 'Vacation';
        } else {
            return '';
        }
    };

    const agencyBrand = getLinkText(agency);
    const headerBgColor = agencyBrand.toLowerCase().includes("vacation") ? "#33c590" : "white";

    useEffect(() => {
        if (logo && agency && firstName && lastName && profilePhoto) {
            setLoading(false);
        }
    }, [logo, agency, firstName, lastName, profilePhoto]);

    const handleResponse = async (userResponse) => {
        try {
            const response = await ApiMethods.postContacted(appsGuid, requestId, userResponse);
            if (response.success) {
                navigate('/thankYou', {
                    state: {
                        logo: logo,
                        agency: agency,
                    }
                });
            } else {
                throw new Error('Something went wrong');
            }
        } catch (error) {
            setErrorMessage('Something went wrong, please try again later');
        }
    };

    if (loading) {
        return (
            <div className="flex justify-center items-center h-screen">
                <TailSpin
                    height="100"
                    width="100"
                    color="#0B829E"
                    ariaLabel="tail-spin-loading"
                    radius="1"
                    visible={true}
                />
            </div>
        );
    }

    return (
        <div className="Contacted" style={{ backgroundColor: '#f8fbfb' }} >
            <header className="shadow-xl p-8 text-center relative" style={{ backgroundColor: headerBgColor }}>
                <a href={agency} target="_blank" rel="noopener noreferrer">
                    <img src={logo} alt="Agency Logo" className="text-center" />
                </a>
            </header>

            <div className="bg-white shadow-2xl pt-20 p-10 mt-24 mb-10 w-full max-w-2xl mx-auto text-center relative">
                <div className="relative">
                    <img
                        src={profilePhoto}
                        alt="Profile"
                        className="absolute top-[-150px] left-1/2 transform -translate-x-1/2 w-32 h-36 object-cover rounded-lg shadow-lg"
                    />
                </div>
                <h1 className="text-3xl text-tlBlue sm:font-lato sm:font-normal sm:text-[32px] sm:leading-[28px] sm:tracking-normal sm:mt-4">
                    {`${firstName.toUpperCase()} ${lastName.toUpperCase()}`}
                </h1>
                <h2 className="text-xl text-tlDarkGray sm:font-lato sm:font-normal sm:text-[20px] sm:leading-[28px] sm:tracking-normal sm:mt-4">
                    Has this agent recently contacted you?
                </h2>

                <div className="flex items-center justify-center flex-col mt-4">
                    <div className="flex flex-row items-start mt-2 gap-x-4">
                        <label className="flex items-center font-bold">
                            <button
                                className="border-2 border-tlBlue bg-white text-tlBlue hover:bg-tlBlue hover:text-white rounded-full font-bold py-3 px-8 transition duration-300"
                                onClick={() => handleResponse('Y')}
                            >
                                YES
                            </button>
                        </label>

                        <label className="flex items-center font-bold">
                            <button
                                className="border-2 border-tlBlue bg-white text-tlBlue hover:bg-tlBlue hover:text-white rounded-full font-bold py-3 px-8 transition duration-300"
                                onClick={() => handleResponse('N')}
                            >
                                NO
                            </button>
                        </label>
                    </div>

                    {errorMessage && (
                        <p className="text-red-500 text-xs mt-4">{errorMessage}</p>
                    )}

                    <div className="block md:hidden border-t border-gray-300 w-full my-4"></div>

                    <h2 className="text-xl p-8 text-tlDarkGray sm:font-lato sm:font-normal sm:text-[16px] sm:leading-[28px] sm:tracking-normal sm:mt-4">
                        Thank you for helping us make {agencyBrand} a better resource for travelers.
                    </h2>
                </div>
            </div>
        </div>
    );
}

export default Contacted;
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { TailSpin } from 'react-loader-spinner';
import ReviewsLanding from './pages/ReviewsLanding';
import SurveyLanding from './pages/SurveyLanding';
import ThankYou from './pages/ThankYou';
import ErrorPage from './pages/ErrorPage';
import WrongUrl from './pages/WrongUrl';
import ApiMethods from './ApiHelper/ApiMethods';
import apiMethodsInstance from './ApiHelper/ApiMethods';
import './App.css';
import Contacted from './pages/Contacted';

function App() {
    return (
        <Router>
            <AppRoutes />
        </Router>
    );
}

function AppRoutes() {
    const [surveyData, setSurveyData] = useState(null);
    const [hasRedirected, setHasRedirected] = useState(false);
    const [isRedirecting, setIsRedirecting] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (hasRedirected || isRedirecting) return;

        const searchParams = new URLSearchParams(location.search);
        const appsGuid = searchParams.get('agt');
        const requestId = searchParams.get('req');
        const url = window.location.href;


        const redirectOldUrls = () => {
            try {
                const pathname = location.pathname;

                //Review
                if (pathname.includes('/reviews/agent_review.aspx') && appsGuid) {
                    navigate(`/review/${appsGuid}`);
                    setHasRedirected(true);
                    return;
                }

                //Survey
                if (pathname.includes('/reviews/agent_experience.aspx') && requestId) {
                    navigate(`/survey/${appsGuid}/${requestId}`);
                    setHasRedirected(true);
                    return;
                }

                //Contacted             
                const contactedMatch = pathname.match(/^\/contacted\/([a-zA-Z0-9-]+)\/([a-zA-Z0-9-]+)$/i);
                if (contactedMatch) {
                    const firstParam = contactedMatch[1];
                    const secondParam = contactedMatch[2];

                    if (secondParam === '0') {
                        getContactedAndRedirect(null, firstParam);
                    } else {
                        getContactedAndRedirect(firstParam, secondParam);
                    }
                    setHasRedirected(true);
                    return;
                }

                // Contacted Old Url (without '/0')
                if (pathname.includes('/reviews/thank_you.aspx') || /contacted\/([a-zA-Z0-9-]+)/.test(pathname)) {
                    let requestId;

                    if (pathname.includes('/reviews/thank_you.aspx')) {
                        const searchParams = new URLSearchParams(location.search);
                        requestId = searchParams.get('req');
                    }

                    const match = pathname.match(/contacted\/([a-zA-Z0-9-]+)/);
                    if (!requestId && match) {
                        requestId = match[1];
                    }

                    if (requestId) {
                        getContactedAndRedirect(null, requestId);
                        setHasRedirected(true);
                        return;
                    }
                }
            } catch (error) {
                navigate('/error');
            }
        };

        const getContactedAndRedirect = async (appsGuid, requestId) => {
            if (isRedirecting) return;
            try {
                setIsRedirecting(true);
                await apiMethodsInstance.login();
                if (!appsGuid) {
                    const oldSurveyResponse = await apiMethodsInstance.getOldSurvey(requestId);
                    const oldSurveyData = JSON.parse(oldSurveyResponse.data);
                    if (oldSurveyData && oldSurveyData.AppsGuid) {
                        appsGuid = oldSurveyData.AppsGuid;
                        requestId = oldSurveyData.RequestId;
                    } else {
                        console.log("error 1");
                        navigate('/error');
                        return;
                    }
                }

                const contactedResponse = await apiMethodsInstance.getContacted(appsGuid, requestId);
                const contactedParsedResponse = JSON.parse(contactedResponse.data);
                const responseEligible = contactedParsedResponse.ResponseEligible;
                const contactedParsedSuccess = JSON.parse(contactedResponse.success);

                const surveyResponse = await apiMethodsInstance.getSurvey(appsGuid, requestId);
                const surveyParsedData = JSON.parse(surveyResponse.data);
                setSurveyData(surveyParsedData);

                if (contactedParsedSuccess) {
                    if (responseEligible) {
                        navigate(`/contacted/${appsGuid}/${requestId}`, {
                            state: {
                                appsGuid: appsGuid,
                                requestId: requestId,
                                logo: surveyParsedData.Agency.ReviewsLogo,
                                agency: surveyParsedData.Agency.HomePageLink,
                                firstName: surveyParsedData.FirstName,
                                lastName: surveyParsedData.LastName,
                                profilePhoto: surveyParsedData.ProfilePhoto,
                            }
                        });
                    } else {
                        navigate(`/thankyou`, {
                            state: {
                                logo: surveyParsedData.Agency.ReviewsLogo,
                                agency: surveyParsedData.Agency.HomePageLink,
                            }
                        });
                    }
                } else {
                    navigate('/error', {
                        state: {
                            logo: surveyParsedData.Agency.ReviewsLogo,
                        }
                    });
                }
            } catch (error) {
                navigate('/error', {
                    state: {
                        logo: surveyData?.Agency?.ReviewsLogo,
                    }
                });
            } finally {
                setIsRedirecting(false);
            }
        };

        const fetchCustomUrl = async () => {
            if (appsGuid) return;

            const urlParts = window.location.hostname.split('.');
            const domain = urlParts[2];
            const agentPath = location.pathname.split('/')[1];
            if (domain && agentPath && !['survey', 'review', 'contacted'].includes(agentPath.toLowerCase())) {
                try {
                    setIsRedirecting(true);
                    const apiResponse = await apiMethodsInstance.getCustomUrl(`${domain}/${agentPath}`);
                    if (apiResponse.success) {
                        const parsedData = JSON.parse(apiResponse.data);
                        if (parsedData.URL) {
                            window.location.href = parsedData.URL;
                        } else {
                            setIsRedirecting(false);
                            navigate('/wrongUrl');
                        }
                    }
                } catch (error) {
                    setIsRedirecting(false);
                    navigate('/error');
                }
            }
        };

        if (!hasRedirected) {
            const parts = location.pathname.split('/');
            const baseRoute = parts[1];

            if (baseRoute && !appsGuid) {
                fetchCustomUrl();
                setHasRedirected(true);
            } else {
                if (baseRoute === 'Survey') {
                    const appsGuid = parts[2];
                    const requestId = parts[3];
                    if (appsGuid && requestId) {
                        navigate(`/survey/${appsGuid}/${requestId}`);
                    } else {
                        navigate('/error');
                    }
                }

                if (baseRoute === 'Review') {
                    const appsGuid = parts[2];
                    if (appsGuid) {
                        navigate(`/review/${appsGuid}`);
                    } else {
                        navigate('/error');
                    }
                }

                if (baseRoute === 'Contacted') {
                    const appsGuid = parts[2];
                    const requestId = parts[3];
                    if (appsGuid && requestId) {
                        getContactedAndRedirect(appsGuid, requestId);
                    } else {
                        navigate('/error');
                    }
                }
            }
        }

        redirectOldUrls();

    }, [location, navigate, hasRedirected, isRedirecting]);

    if (isRedirecting) {
        return (
            <div className="flex justify-center items-center h-screen">
                <TailSpin
                    height="100"
                    width="100"
                    color="#0B829E"
                    ariaLabel="tail-spin-loading"
                    radius="1"
                    visible={true}
                />
            </div>
        );
    }

    return (
        <Routes>
            <Route path="/" element={<WrongUrl />} />
            <Route path="/review/:appsGuid" element={<ReviewsLanding />} />
            <Route path="/survey/:appsGuid/:requestId" element={<SurveyLanding />} />
            <Route path="/contacted/:appsGuid/:requestId" element={<Contacted />} />
            <Route path="/thankyou" element={<ThankYou />} />
            <Route path="/error" element={<ErrorPage />} />
            <Route path="/wrongUrl" element={<WrongUrl />} />
            <Route path="*" element={<WrongUrl />} />
        </Routes>
    );
}

export default App;
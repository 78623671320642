import { useEffect, useState } from 'react';
import StarRatings from 'react-star-ratings';
import '../App.css';
import ApiMethods from '../ApiHelper/ApiMethods';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { TailSpin } from 'react-loader-spinner';

function ReviewsLanding() {
    const location = useLocation();
    const { appsGuid: paramsAppsGuid } = useParams();
    const appsGuid = location.state?.appsGuid || paramsAppsGuid;
    const navigate = useNavigate();

    const [rating, setRating] = useState(0);
    const [recommend, setRecommend] = useState(null);
    const [reviewTitle, setReviewTitle] = useState('');
    const [reviewDescription, setReviewDescription] = useState('');
    const [reviewLocation, setReviewLocation] = useState('');
    const [reviewNickname, setReviewNickname] = useState('');
    const [reviewEmail, setReviewEmail] = useState('');
    const [error, setError] = useState('');
    const [errors, setErrors] = useState({});
    const [reviewData, setReviewData] = useState(null);
    const [recordType, setRecordType] = useState('');
    const [destinationsData, setDestinationsData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [reviewDestination, setReviewDestination] = useState('');
    const [reviewDestinationId, setReviewDestinationId] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const [starDimension, setStarDimension] = useState('50px');

    useEffect(() => {
        const fetchReviewData = async () => {
            try {
                await ApiMethods.login();
                const reviewResponse = await ApiMethods.getReview(appsGuid);
                const reviewParsedData = JSON.parse(reviewResponse.data);

                setReviewData(reviewParsedData);

                if (!reviewParsedData.ReviewsEligible) {
                    const extractedRecordType = reviewParsedData.Agency?.RecordType;
                    setRecordType(extractedRecordType);
                    const ReviewsLogo = reviewParsedData.Agency?.ReviewsLogo || '';
                    setTimeout(() => {
                        handleIneligibility(extractedRecordType, ReviewsLogo);
                    }, 0);
                }

                const destinationsResponse = await ApiMethods.getDestinations(appsGuid);
                const parser = new DOMParser();
                const xmlDoc = parser.parseFromString(destinationsResponse, 'application/xml');

                const destinationsParsedData = Array.from(xmlDoc.getElementsByTagName('ListDestinations')).map(dest => ({
                    id: dest.getElementsByTagName('DestinationId')[0]?.textContent,
                    name: dest.getElementsByTagName('Destination')[0]?.textContent,
                }));
                setDestinationsData(destinationsParsedData);
            } catch (error) {
                setError('Error fetching data.');
                navigate('/wrongUrl');
            } finally {
                setLoading(false);
            }
        };

        fetchReviewData();
    }, [appsGuid, navigate]);

    const handleIneligibility = (recordType, ReviewsLogo) => {
        let header = "We're sorry!";
        let message = "";
        let linkHref = "";
        let linkText = "Contact Us";

        const trimmedRecordType = recordType.trim();

        switch (trimmedRecordType) {
            case 'ETD':
                message = "The Global Travel Collection travel agent you are looking for does not yet have a published profile.";
                linkHref = "https://www.globaltravelcollection.com/contact-us";
                break;
            case 'Barrhead':
                message = "The Barrhead travel agent you are looking for does not yet have a published profile.";
                linkHref = "https://www.barrheadtravel.co.uk/";
                break;
            case 'Altour':
                message = "The Altour travel agent you are looking for does not yet have a published profile.";
                linkHref = "https://www.altour.com/";
                break;
            default:
                message = "It looks like the agent you have selected is no longer a part of our network. Don't worry, we have plenty of other agents to choose from.";
                linkText = "Find Other Agents Now";
                linkHref = "https://www.travelleaders.com/find_travel_agent";
                break;
        }

        try {
            setTimeout(() => {
                navigate('/error', {
                    state: { header, message, linkText, linkHref, logo: ReviewsLogo },
                });
            }, 0);
        } catch (error) {
            console.error("Navigate error:", error);
        }
    };


    const changeRating = (newRating) => {
        setRating(newRating);
        if (errors.rating) {
            setErrors(prevErrors => ({ ...prevErrors, rating: false }));
        }
    };

    const handleRecommendation = (recommendation) => {
        setRecommend(recommendation);
        if (errors.recommend) {
            setErrors(prevErrors => ({ ...prevErrors, recommend: false }));
        }
    };

    const handleTitleChange = (event) => {
        const value = event.target.value;
        setReviewTitle(value);

        if (value.length > 100) {
            setErrors(prevErrors => ({ ...prevErrors, reviewTitleLength: true }));
        } else {
            setErrors(prevErrors => ({ ...prevErrors, reviewTitleLength: false }));
        }

        if (value === '') {
            setErrors(prevErrors => ({ ...prevErrors, reviewTitle: true }));
        } else {
            setErrors(prevErrors => ({ ...prevErrors, reviewTitle: false }));
        }
    };

    const handleDescriptionChange = (event) => {
        const value = event.target.value;
        setReviewDescription(value);

        if (value.length > 2000) {
            setErrors(prevErrors => ({ ...prevErrors, reviewDescriptionLength: true }));
        } else {
            setErrors(prevErrors => ({ ...prevErrors, reviewDescriptionLength: false }));
        }

        if (value === '') {
            setErrors(prevErrors => ({ ...prevErrors, reviewDescription: true }));
        } else {
            setErrors(prevErrors => ({ ...prevErrors, reviewDescription: false }));
        }
    };

    const handleDestinationChange = (event) => {
        const value = event.target.value;
        setReviewDestination(value);

        if (errors.reviewDestination) {
            setErrors(prevErrors => ({ ...prevErrors, reviewDestination: false }));
        }

        // Filter suggestions based on the current input value
        const filteredSuggestions = destinationsData.filter(destination =>
            destination.name.toLowerCase().startsWith(value.toLowerCase())
        );
        setSuggestions(filteredSuggestions);
    };

    const handleDestinationBlur = () => {
        setTimeout(() => {
            setSuggestions([]);
        }, 200);
    };

    const handleDestinationSelect = (suggestion) => {
        setReviewDestination(suggestion.name);
        setReviewDestinationId(suggestion.id);
        setSuggestions([]);
    };

    const handleLocationChange = (event) => {
        setReviewLocation(event.target.value);
        if (errors.reviewLocation) {
            setErrors(prevErrors => ({ ...prevErrors, reviewLocation: false }));
        }
    };

    const handleNicknameChange = (event) => {
        setReviewNickname(event.target.value);
        if (errors.reviewNickname) {
            setErrors(prevErrors => ({ ...prevErrors, reviewNickname: false }));
        }
    };

    const handleEmailChange = (event) => {
        const value = event.target.value;
        setReviewEmail(value);

        if (errors.reviewEmail || errors.reviewEmailValidation) {
            setErrors(prevErrors => ({
                ...prevErrors,
                reviewEmail: false,
                reviewEmailValidation: false
            }));
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault(); // Prevents the default form submission behavior
        setErrors({});
        setError('');

        let newErrors = {};

        if (rating === 0) newErrors.rating = true;
        if (recommend === null) newErrors.recommend = true;
        if (reviewTitle.trim() === '') newErrors.reviewTitle = true;
        if (reviewTitle.length > 100) newErrors.reviewTitleLength = true;
        if (reviewDescription.trim() === '') newErrors.reviewDescription = true;
        if (reviewDescription.length > 2000) newErrors.reviewDescriptionLength = true;

        // Ensure the destination is valid
        const matchingDestination = destinationsData.find(dest => dest.name === reviewDestination);
        if (!matchingDestination) {
            newErrors.reviewDestination = true;
        } else {
            setReviewDestinationId(matchingDestination.id); // Ensure ID is set correctly
        }

        if (reviewDestination.trim() === '' || !reviewDestinationId) newErrors.reviewDestination = true;
        if (reviewLocation.trim() === '') newErrors.reviewLocation = true;
        if (reviewNickname.trim() === '') newErrors.reviewNickname = true;
        if (reviewNickname.length > 50) newErrors.reviewNicknameLength = true;
        if (reviewEmail.trim() === '') newErrors.reviewEmail = true;
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(reviewEmail.trim())) newErrors.reviewEmailValidation = true;

        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            setError('One or more fields requires attention. Please, check and try again.');
            return;
        }

        const submittedInfo = {
            AppsGuid: reviewData?.AppsGuid,
            Rating: rating,
            Recommend: recommend,
            Title: reviewTitle,
            Review: reviewDescription,
            DestinationName: reviewDestination,
            DestinationId: reviewDestinationId,
            Nickname: reviewNickname,
            Email: reviewEmail,
            Location: reviewLocation,
        };

        try {
            const response = await ApiMethods.submitReview(submittedInfo);
            navigate('/thankyou', { state: { logo: reviewData.Agency.ReviewsLogo, agency: reviewData.Agency.HomePageLink } });
        } catch (error) {
            setError('Error submitting review. Please try again later.');
        }
    };

    //Stars dimensions
    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth <= 768) {
                setStarDimension('40px');
            } else {
                setStarDimension('60px');
            }
        };

        window.addEventListener('resize', handleResize);
        handleResize();

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    if (loading || !reviewData) {
        return (
            <div className="flex justify-center items-center h-screen">
                <TailSpin
                    height="100"
                    width="100"
                    color="#0B829E"
                    ariaLabel="tail-spin-loading"
                    radius="1"
                    visible={true}
                />
            </div>
        );
    }

    const profilePhotoUrl = reviewData ? reviewData.ProfilePhoto.replace(/&amp;/g, '&') : '';
    const headerPhotoUrl = reviewData ? reviewData.Agency.ReviewsLogo : '';

    if (reviewData.ReviewsEligible) {
        return (
            <div className="ReviewsLanding" style={{ backgroundColor: '#f8fbfb' }}>
                <header className="bg-white shadow-xl p-8 text-center relative">
                    <a href={reviewData.Agency.HomePageLink} target="_blank" rel="noopener noreferrer">
                        <img
                            src={headerPhotoUrl}
                            alt=""
                            className="text-center"
                        />
                    </a>
                </header>

                <div className="bg-white shadow-2xl pt-20 p-10 mt-24 mb-10 w-full max-w-2xl mx-auto text-center relative">
                    <div className="relative">
                        <img
                            src={profilePhotoUrl}
                            alt="Profile"
                            className="absolute top-[-150px] left-1/2 transform -translate-x-1/2 w-32 h-32 object-cover rounded-lg shadow-lg"
                        />
                    </div>
                    <h2 className="text-lg mb-2 text-tlDarkGray">Please review your experience working with</h2>

                    <h1 className="text-3xl font-bold text-tlBlue">
                        {reviewData ? `${reviewData.FirstName.toUpperCase()} ${reviewData.LastName.toUpperCase()}` : ''}
                    </h1>

                    <div className="block md:hidden border-t border-gray-300 w-full my-4"></div>

                    <p className="text-m mt-10 text-tlDarkGray">
                        How many stars would you give <span className="font-bold">{reviewData ? `${reviewData.FirstName} ${reviewData.LastName}` : ''}</span> as an agent? <span className={`font-bold ${errors.rating ? 'text-tlRed' : 'text-tlBlue'}`}>*</span>
                    </p>

                    <div className="flex flex-col items-center mt-4">
                        <div className="flex flex-col items-center">
                            <StarRatings
                                rating={rating}
                                starRatedColor="#FFD700"
                                starEmptyColor="#d5ebf0"
                                starHoverColor="#FFD700"
                                changeRating={changeRating}
                                numberOfStars={5}
                                starDimension={starDimension}
                                starSpacing="1px"
                                starRatedClassName="star-rated"
                            />
                            <div className="flex justify-between w-full mt-2">
                                <span className="text-xs text-gray-600 font-bold pl-2">POOR</span>
                                <span className="text-xs text-gray-600 font-bold">EXCELLENT</span>
                            </div>
                        </div>
                    </div>

                    <div className="block md:hidden border-t border-gray-300 w-full my-4"></div>

                    <div className="flex items-center justify-center flex-col mt-4">
                        <p className="text-m mt-10 text-tlDarkGray">
                            Would you recommend <span className="font-bold">{reviewData ? `${reviewData.FirstName} ${reviewData.LastName}` : ''}</span> to a friend or family member? <span className={`font-bold ${errors.recommend ? 'text-tlRed' : 'text-tlBlue'}`}>*</span>
                        </p>
                        <div className="flex justify-between mt-4">
                            <button
                                className={`border-2 border-tlBlue rounded-full px-6 py-2 transition duration-300 ${recommend === 'Y' ? 'bg-tlBlue text-white' : 'bg-white text-tlBlue hover:bg-tlBlue hover:text-white'}`}
                                onClick={() => handleRecommendation('Y')}>
                                YES
                            </button>
                            <button
                                className={`border-2 border-tlBlue rounded-full px-6 py-2 transition duration-300 ml-4 ${recommend === 'N' ? 'bg-tlBlue text-white' : 'bg-white text-tlBlue hover:bg-tlBlue hover:text-white'}`}
                                onClick={() => handleRecommendation('N')}>
                                NO
                            </button>
                        </div>
                    </div>

                    <div className="block md:hidden border-t border-gray-300 w-full my-4"></div>

                    <div className="flex items-center justify-center flex-col mt-4">
                        <p className="text-m mt-10 text-tlDarkGray text-center md:text-left">
                            Please review your experience working with <span className="font-bold">{reviewData ? `${reviewData.FirstName} ${reviewData.LastName}` : ''}</span>. <span className="font-bold text-tlBlue">*</span>
                        </p>
                        <p className="text-sm text-center md:text-left mt-4 text-gray-500">
                            Provide a review of your experience and help other {reviewData.Agency.RecordType === 'Barrhead' ? 'travellers' : 'travelers'} learn about the service you received
                            from {reviewData.FirstName}. From the early planning stages to the trip itself, please take into account all phases of the
                            process when writing your review.
                        </p>

                        <h2 className="font-bold pt-10 text-tlDarkGray">  TITLE <span className={`font-bold ${errors.reviewTitle || errors.reviewTitleLength ? 'text-tlRed' : 'text-tlBlue'}`}>*</span></h2>
                        <input
                            type="text"
                            value={reviewTitle}
                            onChange={handleTitleChange}
                            className={`mt-2 px-4 py-2 w-full rounded-full border ${errors.reviewTitle || errors.reviewTitleLength ? 'border-tlRed' : 'border-gray-500'} focus:outline-none focus:border-black`}
                            placeholder="The best trip of our lives!"
                        />
                        {errors.reviewTitleLength && <p className="text-tlRed text-xs">Please, enter no more than 100 characters</p>}

                        <h2 className="font-bold pt-10 text-tlDarkGray">REVIEW <span className={`font-bold ${errors.reviewDescription || errors.reviewDescriptionLength ? 'text-tlRed' : 'text-tlBlue'}`}>*</span></h2>
                        <textarea
                            value={reviewDescription}
                            onChange={handleDescriptionChange}
                            className={`mt-2 px-4 py-2 w-full h-40 md:h-40 lg:h-24 rounded-lg border ${errors.reviewDescription || errors.reviewDescriptionLength ? 'border-tlRed' : 'border-gray-500'} focus:outline-none focus:border-black`}
                            placeholder="Describe your experience here..."
                        />
                        {errors.reviewDescriptionLength && <p className="text-tlRed text-xs">Please, enter no more than 2000 characters</p>}
                    </div>


                    <div className="flex flex-col md:flex-row mt-4 pt-10">
                        <div className="w-full md:w-1/2 mb-4 md:mb-0 md:mr-2">
                            <h2 className="font-bold text-tlDarkGray text-center md:text-left">DESTINATION <span className={`font-bold ${errors.reviewDestination ? 'text-tlRed' : 'text-tlBlue'}`}>*</span></h2>
                            <input
                                type="text"
                                value={reviewDestination}
                                onChange={handleDestinationChange}
                                onBlur={handleDestinationBlur}
                                onFocus={() => {
                                    // Filter suggestions based on the current input value when the input receives focus
                                    const filteredSuggestions = destinationsData.filter(destination =>
                                        destination.name.toLowerCase().startsWith(reviewDestination.toLowerCase())
                                    );
                                    setSuggestions(filteredSuggestions);
                                }}
                                className={`mt-2 px-4 py-2 w-full rounded-full border ${errors.reviewDestination ? 'border-tlRed' : 'border-gray-500'} focus:outline-none focus:border-black`}
                                placeholder="Where did you travel?"
                            />

                            {suggestions.length > 0 && (
                                <ul className="absolute z-10 bg-white border border-gray-500 rounded-lg mt-2 w-full md:w-1/2">
                                    {suggestions.map((suggestion) => (
                                        <li
                                            key={suggestion.id}
                                            onMouseDown={() => handleDestinationSelect(suggestion)} // Handle selection on mouse down
                                            className="cursor-pointer px-4 py-2 hover:bg-gray-200"
                                        >
                                            {suggestion.name}
                                        </li>
                                    ))}
                                </ul>
                            )}
                            {errors.reviewDestination && <p className="text-tlRed text-xs text-center md:text-left">Please, enter the destination you visited by clicking it on the list</p>}
                        </div>
                        <div className="w-full md:w-1/2 md:ml-2">
                            <h2 className="font-bold text-tlDarkGray text-center md:text-left">YOUR LOCATION <span className={`font-bold ${errors.reviewLocation ? 'text-tlRed' : 'text-tlBlue'}`}>*</span></h2>
                            <input
                                type="text"
                                value={reviewLocation}
                                onChange={handleLocationChange}
                                className={`mt-2 px-4 py-2 w-full rounded-full border ${errors.reviewLocation ? 'border-tlRed' : 'border-gray-500'} focus:outline-none focus:border-black`}
                                placeholder="City, State"
                            />
                        </div>
                    </div>
                    <div className="flex flex-col md:flex-row mt-4 md:pt-10">
                        <div className="w-full md:w-1/2 mb-4 md:mb-0 md:mr-2">
                            <h2 className="font-bold text-tlDarkGray text-center md:text-left">NICKNAME <span className={`font-bold ${errors.reviewNickname ? 'text-tlRed' : 'text-tlBlue'}`}>*</span></h2>
                            <input
                                type="text"
                                value={reviewNickname}
                                onChange={handleNicknameChange}
                                className={`mt-2 px-4 py-2 w-full rounded-full border ${errors.reviewNickname ? 'border-tlRed' : 'border-gray-500'} focus:outline-none focus:border-black`}
                                placeholder="AgentFan123"
                            />
                            {errors.reviewNicknameLength && <p className="text-tlBlue text-xs text-center md:text-left">Please enter no more than 50 characters</p>}
                            <p className="text-xs pt-1 text-tlDarkGray text-center md:text-left">Displays with your review.</p>
                        </div>

                        <div className="block md:hidden pt-2 border-t border-gray-300 w-full my-4"></div>

                        <div className="w-full md:w-1/2 md:ml-2">
                            <h2 className="font-bold text-tlDarkGray text-center md:text-left">EMAIL <span className={`font-bold ${errors.reviewEmail ? 'text-tlRed' : 'text-tlBlue'}`}>*</span></h2>
                            <input
                                type="text"
                                value={reviewEmail}
                                onChange={handleEmailChange}
                                className={`mt-2 px-4 py-2 w-full rounded-full border ${errors.reviewEmail ? 'border-tlRed' : 'border-gray-500'} focus:outline-none focus:border-black`}
                                placeholder="Email"
                            />
                            {errors.reviewEmailValidation && <p className="text-tlRed text-xs text-center md:text-left">Please enter a valid email address</p>}
                            <p className="text-xs pt-1 text-tlDarkGray text-center md:text-left"><span className="font-bold">Not public. </span>Only used in cases where we have questions regarding your review.</p>
                        </div>
                    </div>


                    <div className="flex items-center justify-center flex-col pt-10 md:pt-20">
                        <button
                            className="border-2 border-tlBlue bg-white text-tlBlue hover:bg-tlBlue hover:text-white rounded-full font-bold py-3 px-8 transition duration-300"
                            onClick={handleSubmit}>
                            SUBMIT REVIEW
                        </button>
                    </div>
                    {error && (
                        <div className="mt-4 text-xs text-tlRed font-bold">
                            {error}
                        </div>
                    )}
                </div>
            </div>
        );
    }
    return null;
}

export default ReviewsLanding;
